import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ProdService } from "../../servicios/prod-servicios";

@Component({
  selector: "app-linea",
  templateUrl: "./linea.component.html",
  styleUrls: ["./linea.component.css"]
})
export class LineaComponent implements OnInit, OnDestroy {
  linea;
  maestros;
  urlImgNoDisponible;
  maestrosSub;
  urlImgNoDisponibleSub;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prodService:ProdService
  ) {}

  ngOnInit() {
    this.prodService.lineasNivel = "0";
    this.prodService.estaLinea = "";

    if(this.prodService.productosInicioYaCargado){
      console.log("LDC Productos ok")
    } else {
      console.log("LDC No productos, ir a Inicio");
      this.router.navigate(['']);
    }

    this.maestros = [];
    console.log("m0 recibido: " + this.maestros.length);
    this.maestrosSub = this.prodService.maestros$.subscribe(p => {
      this.maestros = p;
      console.log("maestrosSub recibido: " + this.maestros.length);
      //console.log("maeSub: " + JSON.stringify(this.maestros));
    });

    this.urlImgNoDisponibleSub = this.prodService.urlImgNoDisponible$.subscribe(p => {
      this.urlImgNoDisponible = p;
      console.log("urlImgNoDisponible recibido: " + this.urlImgNoDisponible.length); // + this.urlImgNoDisponible
    });

    this.route.paramMap.subscribe(params => {
      this.linea = this.prodService.lineas1[params.get("lineaId")];
      console.log("LDC: " + this.linea.nombreLin);
      console.log("m1 recibido: " + this.maestros.length);
      this.maestros = this.prodService.maestrosSearchXLin(this.linea.lin);
      this.maestros = this.prodService.maestros;
      //console.log("mae0: " + JSON.stringify(this.maestros));
      if(Object.keys(this.maestros).length === 0){
        console.log("mae0: vacío");
      } else {
        console.log("mae0: " + this.maestros[0].nombreMaestro);
      }
      this.urlImgNoDisponible = this.prodService.urlImgNoDisponible;
      console.log("url length: " + this.urlImgNoDisponible.length);
    });
  }

  ngOnDestroy() {
    this.maestrosSub.unsubscribe();
    this.urlImgNoDisponibleSub.unsubscribe();
  }
}
