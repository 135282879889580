import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ProdService } from "../../servicios/prod-servicios";

@Component({
  selector: 'app-maestro',
  templateUrl: './maestro.component.html',
  styleUrls: ['./maestro.component.css']
})
export class MaestroComponent implements OnInit {
  maestro;
  productos;
  urlImgNoDisponible;
  productosSub;
  urlImgNoDisponibleSub;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prodService:ProdService
  ) { }

  ngOnInit() {
    if(this.prodService.productosInicioYaCargado){
      console.log("MC Productos ok")
    } else {
      console.log("MC No productos, ir a Inicio");
      this.router.navigate(['']);
    }

    this.productosSub = this.prodService.productos$.subscribe(p => {
      this.productos = p;
      console.log("productosSub recibido: " + this.productos.length);
      //console.log("prodSub: " + JSON.stringify(this.productos));
    });

    this.urlImgNoDisponibleSub = this.prodService.urlImgNoDisponible$.subscribe(p => {
      this.urlImgNoDisponible = p;
      console.log("urlImgNoDisponible recibido: " + this.urlImgNoDisponible.length); // + this.urlImgNoDisponible
    });

    this.route.paramMap.subscribe(params => {
      this.maestro = this.prodService.maestros[params.get("maestroId")];
      console.log("MC: " + this.maestro.nombreMaestro);
      this.productos = this.prodService.productosSearchXMae(this.maestro.seq);
      this.productos = this.prodService.productos;
      //console.log("mae0: " + JSON.stringify(this.maestros));
      if(Object.keys(this.productos).length === 0){
        console.log("prod0: vacío");
      } else {
        console.log("prod0: " + this.productos[0].nombre);
      }
      this.urlImgNoDisponible = this.prodService.urlImgNoDisponible;
      console.log("url length: " + this.urlImgNoDisponible.length);
    });
  }

  ngOnDestroy() {
    this.productosSub.unsubscribe();
    this.urlImgNoDisponibleSub.unsubscribe();
  }
}