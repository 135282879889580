import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ProdService } from "../../servicios/prod-servicios";

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {
  producto;
  urlImgNoDisponible;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prodService:ProdService
  ) { }

  ngOnInit() {
    if(this.prodService.productosInicioYaCargado){
      console.log("PC Productos ok")
    } else {
      console.log("PC No productos, ir a Inicio");
      this.router.navigate(['']);
    }

    this.route.paramMap.subscribe(params => {

      this.producto = this.prodService.productos[params.get("productId")]; 
      console.log("prod: " + this.producto.codigo);
      this.urlImgNoDisponible = this.prodService.urlImgNoDisponible
    });
  }

}