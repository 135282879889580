import { NgModule } from '@angular/core'; 
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
//import { AngularFireFunctionsModule } from '@angular/fire/functions';


import { AppComponent } from './app.component';
import { TopBarComponent } from './componentes/top-bar/top-bar.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { ContactoComponent } from './componentes/contacto/contacto.component';
import { QuienesComponent } from './componentes/quienes/quienes.component';
import { LineaComponent } from './componentes/linea/linea.component';
import { ProductoComponent } from './componentes/producto/producto.component';
import { MaestroComponent } from './componentes/maestro/maestro.component';

@NgModule({
  imports: [ 
    BrowserModule, 
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: InicioComponent },
      { path: 'quienes', component: QuienesComponent },
      { path: 'lineas/:lineaId', component: LineaComponent },
      { path: 'maestros/:maestroId', component: MaestroComponent },
      { path: 'contacto', component: ContactoComponent}
    ]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // imports firebase/firedatabase, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule // imports firebase/storage only needed for storage features
    //AngularFireFunctionsModule

  ],
  declarations: [ 
    AppComponent, 
    TopBarComponent, 
    InicioComponent, 
    ContactoComponent, 
    QuienesComponent, 
    LineaComponent, 
    ProductoComponent, 
    MaestroComponent 
  ],
  bootstrap:    [ AppComponent ]
})
export class AppModule { }
