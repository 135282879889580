export class UserAut {
    uid: string;
    email: string;
    autorizado: string;
};

export class User {
    uid: string;
    email: string;
    empresa: string;
    nombre: string;
    direccion: string;
    telefonos: string;
};
 