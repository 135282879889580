import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  datosCli;
  constructor() { }

  enviar(): void {

  }

  ngOnInit() {
    this.datosCli = {
      nombreCli: "",
      mailCli: "",
      telfCli: "",
      mensajeCli: ""
    }
  }

}