import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProdService } from "../../servicios/prod-servicios";


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit, OnDestroy {
  empresa: string;
  config;
  configSub;
  logo1;  //corto o delgado
  logo2;  //mediano
  logo3;  //ancho
  constructor(
    private prodService: ProdService
  ) { 
    
  }

  ngOnInit() {
     //************************************************************************ */
    //Suscripción a config$. Corre cada vez que se modifica la config
    this.config = [];
    this.logo1 = "";
    this.logo2 = "";
    this.logo3 = "";
    this.empresa = "";

    this.configSub = this.prodService.config$.subscribe(p => {
      this.config = p;
      this.logo1 = p.logo1;
      this.logo2 = p.logo2;
      this.logo3 = p.logo3;
      this.empresa = p.empresa;
      console.log("configSub recibido: " + JSON.stringify(this.config));
    });

    //************************************************************************ */
 }

  ngOnDestroy(){
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

}