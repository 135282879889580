import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quienes',
  templateUrl: './quienes.component.html',
  styleUrls: ['./quienes.component.css']
})
export class QuienesComponent implements OnInit {
  urlFondo;
  constructor() {
  }
  ngOnInit() {
    this.urlFondo = "https://firebasestorage.googleapis.com/v0/b/firebase-efimov.appspot.com/o/arpsa%2Fslides%2Fquienes.jpg?alt=media&token=63f0c346-6d80-41d3-915e-76702aa65b17";
  }

}