import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
//import { auth } from 'firebase/app';
import { Observable, of } from "rxjs"; //, ReplaySubject
import { map } from "rxjs/operators"; //, tap, catchError, first

import { rutaEmp } from "../../data/config";

import { User, UserAut } from "../interfaces/user";

@Injectable({
  providedIn: "root"
})

export class FireUserService {
  rutaEmp: string = "";

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase
  ) {
    this.rutaEmp = rutaEmp;
    console.log('rutaEmp: ' + this.rutaEmp);
  }

  afLogin(email: string, password: string): any {
    //console.log(email);
    return this.afAuth.signInWithEmailAndPassword(email, password);
    //No pongo then ni catch aquí para que dé los mensajes en el componente que llama
  }

  afRegistrar(email: string, password: string): any {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  afResetearPwd(email: string): any {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  afLogout(): any {
    return this.afAuth.signOut();
  }

  u1: User = new User();
  ua1: UserAut = new UserAut();

  /*{
    id: 0,
    email: '',
    empresa: '',
    nombre: '',
    direccion: '',
    telefonos: ''
  };*/

  count: number = 0;

  getFireUserAut$(uid: string): Observable<UserAut> {
    return this.db
      .list<UserAut>(
        "usuarios/" + this.rutaEmp,
        ref => ref.orderByKey().equalTo(uid) //
      )
      .valueChanges()
      .pipe(
        map(fusa => {
          /*
          console.log(
            "fusa service get 2, long: " +
              fusa.length +
              ", uid: " +
              uid +
              ", 0: " +
              fusa[0].email
          );
          */
          fusa.map(fua => {
            //console.log("getFireUserAut 3: " + fua.email);
            this.ua1 = fua;
          });
          return this.ua1;
        })
      );
  }

  getFireUser$(uid: string): Observable<User> {
    return this.db
      .list<User>(
        this.rutaEmp + "/usuarios/datos",
        ref =>
          //ref.orderByChild('email').equalTo(mail)	//
          ref.orderByKey().equalTo(uid) //
      )
      .valueChanges()
      .pipe(
        map(fus => {
          //si el usr está autorizado pero no se ha creado this.rutaEmp + /usuarios/datos de ese usr,
          //devuelve this.u1 recién creado, que es un objeto tipo User con valores undefined
          if (fus.length === 0) {
            //console.log("fus vacío");
          } else {
            /*
            console.log(
              "fus service get 5, long: " +
                fus.length +
                ", uid: " +
                uid +
                ", 0: " +
                fus[0].email
            );
            */
            fus.map(fu => {
              //console.log(fu);
              //console.log("getFireUser 3: " + fu.nombre);
              this.u1 = fu;
            });
          }
          return this.u1;
        })
      );
  }

  /*
  verifyMail$(): Promise<void> {
    var usr1 = auth().currentUser;
    return usr1.sendEmailVerification();
  }
  verifyMail$(): Promise<void> {
    return this.afAuth.currentUser.sendEmailVerification();
  }
*/
  verifyMail$(): Promise<void> {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
    .then(() => {
      //this.router.navigate(['verify-email']);
    })
  }

  isLoggedIn(): boolean {
    //return this.afAuth.auth.currentUser.emailVerified;
    if(this.afAuth.currentUser){
      return true;
    } else {
      return false;
    }
  }

  createFireUserAut$(
    uid: string,
    mail: string,
    autorizado: string
  ): Promise<void> {
    const objUserAut = { autorizado: autorizado, email: mail };
    //console.log(objUserAut);
    return this.db.list("usuarios/" + this.rutaEmp + "/").set(uid, objUserAut);
  }
  /*
  createFireUser$(uid:string, mail:string): Promise<void> {
    const objUser = {direccion: '.', email:mail, empresa:'.', nombre:'.', telefonos:'.' }
    return this.db.list(this.rutaEmp + '/usuarios/datos/').set(uid, objUser);
  }
*/
}
