import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireStorage } from '@angular/fire/storage';
//import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from "rxjs"; //, ReplaySubject
import { map, tap, take, catchError } from "rxjs/operators"; //, tap, catchError, first

import { rutaEmp } from "../../data/config";

import { Linea, Config, Maestro, Producto, FireProduct } from "../interfaces/product";
import { ProdService } from "./prod-servicios";

@Injectable({
  providedIn: "root"
})
export class FireshopproductsService {
  ls1: Linea[] = [];
  ms1: Maestro[] = [];
  l1: Linea = new Linea();
  m1: Maestro = new Maestro();
  ps1: Producto[] = [];
  ps2: Producto[] = [];
  p1: Producto = new Producto();
  p2: Producto = new Producto();
  count1: number = 0;
  count2: number = 0;
  countlineas: number = 0;
  countmaestros: number = 0;
  cfgs1: Config[] = [];
  cfg1: Config = new Config();
  rutaEmp: string = "";

  constructor(
    private db: AngularFireDatabase ,
    private storage: AngularFireStorage,
    private prodService: ProdService
  ) {
    this.rutaEmp = rutaEmp;
    console.log('rutaEmp: ' + this.rutaEmp);
  }

  getFireLineas$(): Observable<Linea[]> {
    //console.log("gfl1: ls1: " + this.ls1.length);
    if (this.prodService.lineasYaCargado) {
      //console.log("lin service get 0, Líneas ya cargado");
      return of(this.prodService.lineas);
    } else {
      //console.log("lin service get 1, primera carga");
      return this.db
        .list<Linea>(
          this.rutaEmp + "/sitioweb/lineas/datos",
          ref =>
            ref
              .orderByChild("lin")
        )
        .valueChanges()
        .pipe(
          map(lins => {
            //console.log("lins: " + JSON.stringify(lins));
            /*
            console.log(
              "lins service get 2, " +
                lins.length +
                " lineas, 0: " +
                lins[0].lin +
                ", 9: " +
                lins[2].lin
            );
            */
            lins.map(lin => {
              //console.log('lin: ' + lin.lin);
              this.l1 = new Linea();
              this.l1.lin = lin.lin;
              this.l1.nombreLin = lin.nombreLin;
              this.l1.imagen = lin.imagen;
              this.l1.descrip = lin.descrip;
              this.l1.banner = lin.banner;
              this.ls1[this.countlineas] = this.l1;
              this.countlineas++;
            });
            
            //console.log("gfl2: ls1: " + this.ls1.length);
            
            return this.ls1;
          })
        );
    }
  }

  getFireConfig$(): Observable<Config> {
    return this.db
      .list<Config>(this.rutaEmp + "/sitioweb/config")
      .valueChanges()
      .pipe(
          map(cfgs => {
            console.log("gful$ cfgs: " + JSON.stringify(cfgs));
            console.log("gful$ cfgs[0]: " + cfgs[0]);
            cfgs.map(cfg => {
              this.cfg1 = cfg;
            });
            return this.cfg1;
          })
        );
  }

  getFireConfig(): void {
    this
      .getFireConfig$()
      .pipe(take(1))
      .subscribe(cfg => {
          console.log("gfc Config: " + JSON.stringify(cfg));
          this.prodService.configUpdate(cfg);
          this.prodService.configLoad();
        }
      );
  }

  setFireConfig$(cfg:string, modeloCfg: Config): Promise<void> {
    //cfg por ahora es siempre '001'
    return this.db
      .list(this.rutaEmp + "/sitioweb/config/")
      .set(cfg, modeloCfg);
  }


  getFireMaestros$(): Observable<Maestro[]> {
    //console.log("gfl1: ms1: " + this.ms1.length);
    if (this.prodService.maestrosCompletoYaCargado) {
      //console.log("lin service get 0, Líneas ya cargado");
      return of(this.prodService.maestros);
    } else {
      //console.log("mae service get 1, primera carga");
      return this.db
        .list<Maestro>(
          this.rutaEmp + "/sitioweb/maestros/datos",
          ref =>
            ref
              .orderByChild("seq")
        )
        .valueChanges()
        .pipe(
          map(maes => {
            //console.log("maes: " + JSON.stringify(maes));
            /*
            console.log(
              "maes service get 2, " +
                maes.length +
                " maestros, 0: " +
                maes[0].mae +
                ", 9: " +
                maes[2].mae
            );
            */
            maes.map(mae => {
              //console.log('mae: ' + mae.seq);
              this.m1 = new Maestro();
              this.m1.seq = mae.seq;
              this.m1.lin = mae.lin;
              this.m1.nombreMaestro = mae.nombreMaestro;
              this.m1.descrip = mae.descrip;
              this.m1.imagen = mae.imagen;
              this.m1.pdf = mae.pdf;
              this.ms1[this.countmaestros] = this.m1;
              this.countmaestros++;
            });
            
            //console.log("gfl2: ms1: " + this.ms1.length);
            
            return this.ms1;
          })
        );
    }
  }

  getFireProductsInicio$(vieneDesde: string): Observable<Producto[]> {
    //console.log("gfp1: ps1: " + this.ps1.length + ", ps2: " + this.ps2.length);
    if (this.prodService.productosInicioYaCargado || vieneDesde === "app.component") {
      /*
      console.log(
        "fsp service get 0, productsInicio ya cargado, viene desde: " +
          vieneDesde
      );
      */
      return of(this.prodService.productosInicio);
    } else {
      /*
      console.log(
        "fsp service get 1, primera carga, viene desde: " + vieneDesde
      );
      */
      return this.db
        .list<FireProduct>(
          this.rutaEmp + "/articulos/datos",
          ref =>
            ref
              .orderByChild("webVer")
              .equalTo("S")
        )
        .valueChanges()
        .pipe(
          map(fps => {
            //console.log("fps len: " + fps.length);
            //console.log("fps: " + JSON.stringify(fps));
            /*
            console.log(
              "fsp service get 2, " +
                fps.length +
                " art, viene desde: " +
                vieneDesde +
                ", 0: " +
                fps[0].codigoart +
                ", 9: " +
                fps[9].codigoart
            );
            */
            fps.map(fp => {
              //console.log("ps1 len: " + this.ps1.length);
              //console.log('get 3: ' + fp.nombreart);
              /*
              this.p1 = new Product();
              this.p1.id = Number(fp.numart);
              this.p1.name = fp.nombreart;
              this.p1.codigo = fp.codigoart;
              this.p1.numart = fp.numart;
              this.p1.price = fp.pul1art;
              this.p1.compareAtPrice =
                parseInt(fp.numart) % 4 === 0
                  ? Math.round(fp.pul1art * 1.3 * 100) / 100
                  : null;
              //console.log(this.p1.compareAtPrice===null? 'nulo' : fp.numart + ':'  + this.p1.compareAtPrice.toString());
              this.p1.images = [];
              this.p1.imgs = fp.imgs;
              let imgs: string = "0000";
              if (typeof fp.imgs !== "undefined") {
                imgs = fp.imgs.toString(); //acá llega solo lo significativo, ej. '11'
                imgs = "0".repeat(4 - imgs.length) + imgs; //para completar con '0', ej: '0011'
              }
              //console.log(imgs);
              [1, 2, 3, 4].map(ind => {
                //en imgs el orden de las img está al revés, la img 0 está representada al final
                //ej: 0011 significa que las img 1 y 2 existen, y que las 3 y 4 no
                if (imgs.slice(4 - ind, 5 - ind) === "1") {
                  this.p1.images[ind - 1] = fp["img" + ind];
                } else {
                  this.p1.images[ind - 1] =
                    "assets/images/products/product-1.jpg";
                }
              });
              this.p1.badges = ["new"];
              this.p1.rating = 5;
              this.p1.reviews = 0;
              this.p1.availability =
                fp.cantart > 0 ? "Disponible" : "No disponible";
              this.p1.features = [
                { name: "Marca", value: "Superior" },
                { name: "Material", value: "Fibra de vidrio" },
                { name: "Color", value: "Negro" }
              ];
              this.p1.options = [];
              */
              this.p1 = new Producto();
              this.p1.id = Number(fp.numart);
              this.p1.nombre = fp.nombreart;
              this.p1.codigo = fp.codigoart;
              this.p1.numart = fp.numart;
              this.p1.precio = fp.pul1art;
              //console.log(this.p1.compareAtPrice===null? 'nulo' : fp.numart + ':'  + this.p1.compareAtPrice.toString());
              this.p1.imagenes = [];
              this.p1.imgs = fp.imgs;
              let imgs: string = "0000";
              if (typeof fp.imgs !== "undefined") {
                imgs = fp.imgs.toString(); //acá llega solo lo significativo, ej. '11'
                imgs = "0".repeat(4 - imgs.length) + imgs; //para completar con '0', ej: '0011'
              }
              //console.log(imgs);
              [1, 2, 3, 4].map(ind => {
                //en imgs el orden de las img está al revés, la img 0 está representada al final
                //ej: 0011 significa que las img 1 y 2 existen, y que las 3 y 4 no
                if (imgs.slice(4 - ind, 5 - ind) === "1") {
                  this.p1.imagenes[ind - 1] = fp["img" + ind];
                } else {
                  this.p1.imagenes[ind - 1] =
                    "assets/images/products/product-1.jpg";
                }
              });
              this.p1.webDescrip = fp.webDescrip;
              this.p1.webMaestro = fp.webMaestro;

              this.ps1[this.count1] = this.p1;
              //console.log("ps1 len: " + this.ps1.length);
              this.count1++;
            });
            /*
            console.log(
              "gfp2: ps1: " + this.ps1.length + ", ps2: " + this.ps2.length
            );
            */
            console.log("ps1 len final: " + this.ps1.length);
            return this.ps1;
          })
        );
    }
  }

  getFireProductsCompleto$(vieneDesde: string): Observable<Producto[]> {
    //console.log("gfp3: ps1: " + this.ps1.length + ", ps2: " + this.ps2.length);
    if (this.prodService.productosCompletoYaCargado) {
      /*
      console.log(
        "fspc service get 0, products ya cargado, viene desde: " + vieneDesde
      );
      */
      return of(this.prodService.productosCompleto);
    } else {
      /*
      console.log(
        "fspc service get 1, primera carga, viene desde: " + vieneDesde
      );
      */
      return this.db
        .list<FireProduct>(this.rutaEmp + "/articulos/datos", ref =>
          ref
            .orderByChild("webVer")
            .equalTo("S")
        )
        .valueChanges()
        .pipe(
          map(fpsc => {
            /*
            console.log(
              "fspc service get 2, " +
                fpsc.length +
                " art, viene desde: " +
                vieneDesde +
                ", 0: " +
                fpsc[0].codigoart +
                ", 9: " +
                fpsc[9].codigoart
            );
            */
            fpsc.map(fp => {
              //console.log('get 3: ' + fp.name);
              this.p2 = new Producto();
              this.p2.id = Number(fp.numart);
              this.p2.nombre = fp.nombreart;
              this.p2.codigo = fp.codigoart;
              this.p2.numart = fp.numart;
              this.p2.precio = fp.pul1art;
              /*
              this.p2.compareAtPrice =
                parseInt(fp.numart) % 4 === 0
                  ? Math.round(fp.pul1art * 1.3 * 100) / 100
                  : null;
              */
              //console.log(this.p1.compareAtPrice===null? 'nulo' : fp.numart + ':'  + this.p1.compareAtPrice.toString());
              this.p2.imagenes = [];
              this.p2.imgs = fp.imgs;
              let imgs: string = "0000";
              if (typeof fp.imgs !== "undefined") {
                imgs = fp.imgs.toString(); //acá llega solo lo significativo, ej. '11'
                imgs = "0".repeat(4 - imgs.length) + imgs; //para completar con '0', ej: '0011'
              }
              //console.log(imgs);
              [1, 2, 3, 4].map(ind => {
                //en imgs el orden de las img está al revés, la img 0 está representada al final
                //ej: 0011 significa que las img 1 y 2 existen, y que las 3 y 4 no
                if (imgs.slice(4 - ind, 5 - ind) === "1") {
                  this.p2.imagenes[ind - 1] = fp["img" + ind];
                } else {
                  this.p2.imagenes[ind - 1] =
                    "assets/images/products/product-1.jpg";
                }
              });
              /*
              this.p2.badges = ["new"];
              this.p2.rating = 5;
              this.p2.reviews = 0;
              this.p2.availability =
                fp.cantart > 0 ? "Disponible" : "No disponible";
              this.p2.features = [
                { name: "Marca", value: "Superior" },
                { name: "Material", value: "Fibra de vidrio" },
                { name: "Color", value: "Negro" }
              ];
              this.p2.options = [];
              */
              this.p2.webDescrip = fp.webDescrip;
              this.p2.webMaestro = fp.webMaestro;
              this.ps2[this.count2] = this.p2;
              this.count2++;
            });
            /*
            console.log(
              "gfp4: ps1: " + this.ps1.length + ", ps2: " + this.ps2.length
            );
            */
            return this.ps2;
          })
        );
    }
  }

  GetFireImgNoDisponible$(): Observable<string | null>{
		//console.log(this.rutaEmp + '/imgNoDisponible.jpg);
		const ref = this.storage.ref(this.rutaEmp + '/imgNoDisponible.jpg');
		return ref.getDownloadURL()
		.pipe(
			tap(url => {
				console.log('ok : ' + this.rutaEmp + '/imgNoDisponible.jpg');
				return url;
			}),
			catchError(err => {
				console.log(err.code + ': ' + this.rutaEmp + '/imgNoDisponible.jpg');
				return 'urlError: ' + err.code;
			})
		)
	}

}
