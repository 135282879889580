export class Linea {
  lin : string;
  nombreLin : string;
  imagen : string;
  descrip : string;
  ultSeq : number;
  banner : string;
}

export class Config {
  slide1 : string;
  txtSl1 : string;
  slide2 : string;
  txtSl2 : string;
  slide3 : string;
  txtSl3 : string;
  ultLin : number;
  ultSLin1 : number;
  ultSLin2 : number;
  ultMae : number;
  empresa : string;
  okTimerSlides : boolean;
  valTimerSlides : number;
  logo1 : string;
  logo2 : string;
  logo3 : string;
  logLevel : number;
}

export class Maestro {
  seq : string;
  lin : string;
  nombreMaestro : string;
  descrip : string;
  imagen : string;
  pdf : string;
}

export class FireProduct {
  artbodega : {
    '01-M' : number,
    '02-A' : number,
    '03-D' : number,
    '04-F' : number,
    '05-I' : number
  };
  c1art : string;
  cantart : number;
  codigoart : string;
  cuart : number;
  descart : string;
  desctoart : number;
  desctoautoart : string;
  nombreart : string;
  numart : string;
  pul1art : number;
  pul2art : number;
  sujivaart : string;
  tipoart : string;
  imgs : number;
  img1 : string;
  img2 : string;
  img3 : string;
  img4 : string;
  webVer : string;
  webDescrip : string;
  webMaestro : string;
}
/*
export interface ProductFeature {
    name: string;
    value: string;
}

export class Product { 
    id: number;
    name: string;
    codigo: string;
    price: number;
    compareAtPrice: number|null;
    images: string[];
    imgs: number;
    badges: ('sale'|'new'|'hot')[];
    rating: number;
    reviews: number;
    availability: string;
    features: ProductFeature[];
    options: Array<any>;
    numart: string;
}
*/

export class Producto { 
    id: number;
    nombre: string;
    codigo: string;
    precio: number;
    imagenes: string[];
    imgs: number;
    numart: string;
    webMaestro: string;
    webDescrip: string;
}