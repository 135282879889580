import { Injectable } from "@angular/core"; 
import { User, UserAut } from '../interfaces/user'; 
import { ReplaySubject } from 'rxjs'

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(
  ) {}

  userVacio: User = {
      uid: '',
      email: '',
      empresa: '',
      nombre: '',
      direccion: '',
      telefonos: ''
  };

  userAutVacio: UserAut = {
      uid: '',
      email: '',
      autorizado: 'N'
  };

  //user y userAut para los que necesitan un dato normal, componentes que se cargan cuando el dato está definido 
  //y no va a cambiar durante su visualización, por ej page home xxx
  user: User = this.userVacio;
  userYaCargado: boolean = false;   //true: muestra artículos
  userAut: UserAut = this.userAutVacio;

  //user$ y userAut$ para los que necesitan un Observable, como componentes que están mostrados y de pronto cambia el valor,
  //por ejemplo en nav.component.ts se cargaba userAut una sola vez (no autorizado, mostraba cantidad 0) y no se actualizaba.
  //1: creo un ReplaySubject (ej userAut_) (cuando usé Subject funcionó bien la primera vez pero al navegar se perdía la cantidad)
  //2: creo y exporto un Observable que apunta al Subject (ej userAut$)
  //3: cuando hay un cambio (en los updates) actualizo el observable ( ej userAut_.next(usrAut))
  //4: en los componentes que lo necesiten, me suscribo al observable y actualizo una propiedad normal como this.userAut (ej:
  //          userAutSub = userAut$.subscribe(u => {
  //              this.userAut = u;
  //          });)
  //Si se usa suscripción no hace falta Async en el HTML
  //En los updates actualizo tanto el normal como el observable
  user_ = new ReplaySubject<User>(1);
  user$ = this.user_.asObservable();
  userAut_ = new ReplaySubject<UserAut>(1);
  userAut$ = this.userAut_.asObservable();

  userUpdate(usr: User){
      this.user = usr;
      this.user_.next(usr);
  }

  userYaCargadoUpdate(VF:boolean) : void {
      this.userYaCargado = VF;    
  } 

  userAutUpdate(usrAut: UserAut){
      this.userAut = usrAut;    
      this.userAut_.next(usrAut);
  }

  userPrueba: User = {
      uid: 'id de usuario, ej: simplelogin:1',
      email: 'correo@mail.com',
      empresa: 'Empresa Cía. Ltda.',
      nombre: 'Usuario de Efisys',
      direccion: 'Av. de la Prensa N65-756 y Av. del Maestro',
      telefonos: '2233445/2587963'
  };
}
