// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
	firebase: {
		apiKey: "AIzaSyDEYrSVbQmALe3Hk2kymPT0C30ztAhN2Fw",
		authDomain: "efimov.firebaseapp.com",
		databaseURL: "https://efimov.firebaseio.com",
		projectId: "firebase-efimov",
		storageBucket: "firebase-efimov.appspot.com",
		messagingSenderId: "754300534099",
		appId: "1:754300534099:web:ae8e4ad965348ea6"
	}    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
