import { Injectable } from "@angular/core";
import { Linea, Config, Maestro, Producto } from '../interfaces/product';
import { ReplaySubject } from 'rxjs'

@Injectable({
  providedIn: "root"
})
export class ProdService {

  constructor(
  ) {}

  lineas: Linea[] = [];
  lineas_ = new ReplaySubject<Linea[]>(1);
  lineas$ = this.lineas_.asObservable();
  lineasYaCargado: boolean = false;
  lineasYaCargado_ = new ReplaySubject<boolean>(1);
  lineasYaCargado$ = this.lineasYaCargado_.asObservable();
  //para saber si mostrar las líneas de nivel 0, 1 o 2, por ahora solo hay 0 o 1
  lineasNivel: string = '0';  
  //si estamos viendo las lineas de nivel 0 es "", si es nivel 1 es la línea madre de lo que estoy viendo
  estaLinea: string = "";    
  lineas1: Linea[] = [];

  lineasLoad(){
    //console.log('lineasLoad');
    this.lineas_.next(this.lineas);
  }

  lineasUpdate(lins: Linea[]){
      this.lineas = lins;
      //console.log('lineasUpdate');
      this.lineasLoad();
  }

  lineasYaCargadoUpdate(){
      this.lineasYaCargado = true;    
  }

  filtraLinNivel(nivel:string, lin:string): Linea[] {
    let l1:Linea[] = [];
    this.lineas.map(a=>{
      if(nivel=='0'){
        if(a.lin.length==3){
          l1.push(a);
        }
      }
      if(nivel=='1'){
        if(a.lin.length==6 && a.lin.slice(0,3)==lin){
          l1.push(a);
        }
      }
    })
    console.log("filtraLinNivel nivel: " + nivel + ", lin: " + lin + ", l1.length: " + l1.length);
    this.lineas1 = l1;
    return l1;
  }

  buscaLinSubNiveles(nivel:string,lin:string): boolean {
    let b1:boolean = false;
    this.lineas.map(a=>{
      if(!b1 && nivel=='0'){    //el !b1 es para que ya no busque una vez que encuentre un subnivel de una línea
        if(a.lin.slice(0,3)==lin && a.lin!=lin){
          b1=true;
        }
      }
      if(!b1 && nivel=='1'){
        if(a.lin.slice(0,6)==lin && a.lin!=lin){
          b1=true;
        }
      }
    })
    console.log("buscaLinSubNiveles nivel: " + nivel + ", lin: " + lin + ", b1: " + b1);
    return b1;
  }


  config: Config = new Config();
  config_ = new ReplaySubject<Config>(1);
  config$ = this.config_.asObservable();

  configLoad() {
    //console.log('configLoad');
    this.config_.next(this.config);
  }

  configUpdate(cfg: Config) {
    this.config = cfg;
    //console.log('config Update');
    this.configLoad();
  }


  maestros: Maestro[] = [];
  maestros_ = new ReplaySubject<Maestro[]>(1);
  maestros$ = this.maestros_.asObservable();
  maestrosCompleto: Maestro[] = [];
  maestrosCompletoYaCargado: boolean = false;
  //maestrosYaCargado_ = new ReplaySubject<boolean>(1);
  //maestrosYaCargado$ = this.maestrosYaCargado_.asObservable();

  maestrosLoad(){
    console.log('maestrosLoad');
    console.log("mL 1 maestros: " + this.maestros.length);
    this.maestros_.next(this.maestros);
    console.log("mL 2 maestros: " + this.maestros.length);
  }

  maestrosCompletoUpdate(maes: Maestro[]){
      this.maestrosCompleto = maes;
      this.maestros = maes;
      console.log("mCU 1 maestros: " + this.maestros.length);
      //console.log('maestrosUpdate');
      this.maestrosLoad();
      console.log("mCU 2 maestros: " + this.maestros.length);
  }

  maestrosCompletoYaCargadoUpdate(){
      this.maestrosCompletoYaCargado = true;  
      console.log("mCYC 1 maestros: " + this.maestros.length);
      //this.maestrosCompletoYaCargado_.next(true);
  }


  //productos es lo que todo el tema busca, pero debe mostrar info distinta en diferentes rutas.
  //Voy a cargar diferentes productosXXX y pasarlos a productos según haga falta.
  //Por ejemplo al entrar se necesitan promociones y nuevos, en la primera lectura grabo en productosInicio y productos. 
  //La info completa está en productosCompleto

  //Utilizo el método de ReplaySubject definido en user-servicios.ts
  //para informar al componente consumidor de productos la long de datos enviados
  productos_ = new ReplaySubject<Producto[]>(1);
  productos$ = this.productos_.asObservable();
  //Esto es para informar que ya se ha cargado productosCompleto y recién en ese momento habilitar el menú Tienda
  productosCompletoYaCargado_ = new ReplaySubject<boolean>(1);
  productosCompletoYaCargado$ = this.productosCompletoYaCargado_.asObservable();

  urlImgNoDisponible_ = new ReplaySubject<string>(1);
  urlImgNoDisponible$ = this.urlImgNoDisponible_.asObservable();

  urlImgNoDisponibleYaCargado_ = new ReplaySubject<boolean>(1);
  urlImgNoDisponibleYaCargado$ = this.urlImgNoDisponibleYaCargado_.asObservable();

  ultimoPedido: string = 'Inicio';
  productos: Producto[] = [];

  productosLoad(tipo: string){
      console.log('productosLoad, tipo: ' + tipo);
      this.ultimoPedido = tipo;
      switch(tipo){
          case 'Inicio':{
              this.productos = this.productosInicio;
              //console.log("prodLoad: " + JSON.stringify(this.productos));
              //console.log("prodLoad: " + this.productos.length);
              this.productos_.next(this.productos);
              break;
          }
      }
  }

  productosInicio: Producto[] = [];
  productosInicioUpdate(fps: Producto[]){
      this.productosInicio = fps;
      //console.log('productosInicioUpdate, ultimoPedido: ' + this.ultimoPedido);
      //console.log("productosInicioUpdate: " + JSON.stringify(this.productosInicio));
      //console.log("productosInicioUpdate len: " + this.productosInicio.length);
      this.productosLoad(this.ultimoPedido);
  }
  productosInicioYaCargado: boolean = false;
  productosInicioYaCargadoUpdate(){
      this.productosInicioYaCargado = true;    
  }

  productosCompleto: Producto[] = [];
  productosCompletoUpdate(fps: Producto[]){
      this.productosCompleto = fps;
      //console.log('productosCompletoUpdate, ultimoPedido: ' + this.ultimoPedido);
      this.productosLoad(this.ultimoPedido);
  }
  productosCompletoYaCargado: boolean = false;
  productosCompletoYaCargadoUpdate(){
      this.productosCompletoYaCargado = true;
      this.productosCompletoYaCargado_.next(true);
  }

  filtraMaeXLin(filtro:string):Maestro[]{
      const filtro1:string = filtro.replace(/\*/g,'.*');
      //console.log("filtro1: " + filtro1);
      let i=0;
      let l=[];
      console.log("filtraMXL maestrosCompleto: " + this.maestrosCompleto.length);
      this.maestrosCompleto.map(function(a){
          //console.log('a.nombreMaestro: ' + a.nombreMaestro);
          //console.log('a.lin: ' + a.lin);
          if((a.lin.toUpperCase()).search(filtro1.toUpperCase())!=-1){
              //a.indice=i;
              //console.log("map:" + a.lin + " " + a.nombreMaestro);
              l.push(a);
          }
          i++;
      });
      console.log("filtraMXL l: " + l.length);
      return l;
  };

  maestrosSearchXLin(busq: string){
      //console.log('search: ' + busq);
      this.maestros = [];
      this.maestros = this.filtraMaeXLin(busq);
      console.log("searchMXL 1 maestros: " + this.maestros.length);
      this.maestros_.next(this.maestros);
      console.log("searchMXL 2 maestros: " + this.maestros.length);
  }

  filtraArtXMae(filtro:string):Producto[]{
      const filtro1:string = filtro.replace(/\*/g,'.*');
      //console.log("filtro1: " + filtro1);
      let i=0;    //para tener en ListaArticulos1 la ubicación del Art en el arreglo original ListaArticulos (parámetro para ArticuloDet)
      let l=[];
      this.productosCompleto.map(function(a){
          console.log('a.webMaestro: ' + a.webMaestro);
          console.log('a.numart: ' + a.numart);
          if((a.webMaestro.toUpperCase()).search(filtro1.toUpperCase())!=-1){
              //a.indice=i;
              //console.log("map:" + a.lin + " " + a.nombreMaestro);
              l.push(a);
          }
          i++;
      });
      return l;
  };

  productosSearchXMae(busq: string){
      console.log('searchXMae: ' + busq);
      this.productos = this.filtraArtXMae(busq);
      this.productos_.next(this.productos);
  }

  filtraArt(filtro:string){
      const filtro1:string = filtro.replace(/\*/g,'.*');
      //console.log("filtro1: " + filtro1);
      let i=0;    //para tener en ListaArticulos1 la ubicación del Art en el arreglo original ListaArticulos (parámetro para ArticuloDet)
      let l=[];
      this.productosCompleto.map(function(a){
          //console.log('a.nombre: ' + a.nombre);
          //console.log('a.codigo: ' + a.codigo);
          if((a.nombre.toUpperCase()).search(filtro1.toUpperCase())!=-1 || (a.codigo.toUpperCase()).search(filtro1.toUpperCase())!=-1 ){
              //a.indice=i;
              //console.log("map:" + a.codigo + " " + a.nombre);
              l.push(a);
          }
          i++;
      });
      return l;
  };

  productosSearch(busq: string){
      console.log('search: ' + busq);
      this.productos = this.filtraArt(busq).slice(0,100);
      this.productos_.next(this.productos);
  }

  urlImgNoDisponible: string = "";
  urlImgNoDisponibleUpdate(url1: string){
      this.urlImgNoDisponible = url1;
      //console.log('urlImgNoDisponibleUpdate: '); //+ url1
      this.urlImgNoDisponible_.next(this.urlImgNoDisponible);
  }

  urlImgNoDisponibleYaCargado: boolean = false;
  urlImgNoDisponibleYaCargadoUpdate(){
      this.urlImgNoDisponibleYaCargado = true;
      this.urlImgNoDisponibleYaCargado_.next(true);
  }
}
