import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators"; //, tap, catchError, first

import { FireUserService } from '../../servicios/fire-user-servicios';
import { UserService } from '../../servicios/user-servicios';
import { FireshopproductsService } from "../../servicios/fire-prod-servicios";
import { ProdService } from "../../servicios/prod-servicios";
import { Linea } from "../../interfaces/product";


@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.css"]
})
export class InicioComponent implements OnInit, OnDestroy {
  lineas;     //líneas y sublíneas
  lineas1;     //lo que se ve en el HTML
  lineasSub;
  productos;
  config;
  configSub;
  slide1;
  slide2;
  slide3;
  txtSl1;
  txtSl2;
  txtSl3;
  empresa;
  okTimerSlides;
  valTimerSlides;

  constructor(
    private fireshopproductsService: FireshopproductsService,
    private router: Router,
    private authService: FireUserService,
    private userService: UserService,
    private prodService: ProdService
  ) {
    //this.lineas = this.prodService.lineas;
  }

  getFireLineas(): void {
    console.log("inicio: carga lineas");
    this.fireshopproductsService
      .getFireLineas$()
      .pipe(take(1)) //.pipe(takeUntil(this.destroy$))
      .subscribe(lins => {
        //this.zone.run(() => {
        if (this.prodService.lineasYaCargado) {
          /*
          console.log(
            "login get lin Ya Cargado, 0: " +
              this.lineas[0].lin +
              ", ---- 1: " +
              this.lineas[1].lin
          );
          */
          this.prodService.lineasLoad(); //carga líneas
        } else {
          this.prodService.lineasUpdate(lins); //productos = fps;
          this.prodService.lineasYaCargadoUpdate(); //para no cargar otra vez
          
          //console.log("lins: " + JSON.stringify(this.lineas));
          /*
          console.log(
            "login get lin, 0: " +
              this.lineas[0].lin +
              ", ---- 1: " +
              this.lineas[1].lin
          );
          */
          this.prodService.lineasLoad(); //carga líneas
        }
        //});
      });
  }

  getFireMaestros(): void {
    //console.log("login: carga ps");
    this.fireshopproductsService
      .getFireMaestros$()
      .pipe(take(1)) //.pipe(takeUntil(this.destroy$))
      .subscribe(maes => {
        //this.zone.run(() => {
        if (this.prodService.maestrosCompletoYaCargado) {
          /*
          console.log(
            "login get mae Ya Cargado, 0: " +
              this.maestros[0].mae +
              ", ---- 1: " +
              this.maestros[1].mae
          );
          */
          this.prodService.maestrosLoad(); //carga líneas
        } else {
          this.prodService.maestrosCompletoUpdate(maes); //maestros = maes;
          this.prodService.maestrosCompletoYaCargadoUpdate(); //para no cargar otra vez
          
          //console.log("maes: " + JSON.stringify(this.maestros));
          /*
          console.log(
            "login get mae, 0: " +
              this.maestros[0].mae +
              ", ---- 1: " +
              this.maestros[1].mae
          );
          */
          this.prodService.maestrosLoad(); //carga líneas
        }
        //});
      });
  }

  getFireProducts(): void {
    //console.log("login: carga ps");
    this.fireshopproductsService
      .getFireProductsInicio$("login")
      .pipe(take(1)) //.pipe(takeUntil(this.destroy$))
      .subscribe(fps => {
        //this.zone.run(() => {
        if (this.prodService.productosInicioYaCargado) {
          /*
          console.log(
            "login get fp Ya Cargado, 0: " +
              productosInicio[0].name +
              ", ---- 9: " +
              productoInicio[9].name
          );
          */
          this.prodService.productosLoad("Inicio"); //carga productos
        } else {
          this.prodService.productosInicioUpdate(fps); //productos = fps;
          this.prodService.productosInicioYaCargadoUpdate(); //para no cargar otra vez
          
          /*console.log(
            "login get fp, 0: " +
              productosInicio[0].name +
              ", ---- 9: " +
              productosInicio[9].name
          );
          */
          this.prodService.productosLoad("Inicio"); //carga productos
        }
        //});
      });
  }

  getFireProductsCompleto(): void {
    //console.log("login: carga ps Completo");
    this.fireshopproductsService
      .getFireProductsCompleto$("login")
      .pipe(take(1)) //.pipe(takeUntil(this.destroy$))
      .subscribe(fpsc => {
        //this.zone.run(() => {
        if (this.prodService.productosCompletoYaCargado) {
          console.log("get fp completo Ya Cargado");
          /*
          console.log(
            "login get fp completo Ya Cargado, 0: " +
              productosCompleto[0].name +
              ", ---- 9: " +
              productosCompleto[9].name
          );
          */
        } else {
          console.log("get fp completo aún no cargado");
          fpsc.sort((a, b) => {
            if (a.nombre.toUpperCase() > b.nombre.toUpperCase()) {
              return 1;
            } //mayor
            if (a.nombre.toUpperCase() < b.nombre.toUpperCase()) {
              return -1;
            } //menor
            return 0; //igual
          });
          this.prodService.productosCompletoUpdate(fpsc); //productosCompleto = fps;
          this.prodService.productosCompletoYaCargadoUpdate(); //para no cargar otra vez
          //alert('fin productosCompleto')
          /*
          console.log(
            "login get fp completo, 0: " +
              productosCompleto[0].name +
              ", ---- 9: " +
              productosCompleto[9].name
          );
          */
        }
        //});
      });
  }

  GetFireImgNoDisponible(): void {
    //console.log("login: trae URL imgNoDisponible");
    this.fireshopproductsService
      .GetFireImgNoDisponible$()
      .pipe(take(1)) //.pipe(takeUntil(this.destroy$))
      .subscribe(url1 => {
        if (this.prodService.urlImgNoDisponibleYaCargado) {
          console.log("Inicio urlImgNoDisponible Ya Cargado");
        } else {
          this.prodService.urlImgNoDisponibleUpdate(url1); 
          this.prodService.urlImgNoDisponibleYaCargadoUpdate(); //para no cargar otra vez
          console.log("Inicio urlImgNoDisponible ok: "); //+ urlImgNoDisponible
        }
        //});
      });
  }

  modeloLogin = {
    mail: "",
    pwd: "",
    mailReg: "",
    pwdReg1: "",
    pwdReg2: "",
    userYaCargado: false,
    empresa: ""     //this.empresa
  };
  mensajeLogin: string = "";
  mensajeLogin2: string = "";
  mensajeRegistro: string = "";
  mensajeRegistro2: string = "";
  mensajeLogout: string = "";
  mensajeLogout2: string = "";

  loginSubmit() {
    this.mensajeLogin = "";
    this.mensajeLogin2 = "";
    this.mensajeRegistro = "";
    this.mensajeRegistro2 = "";
    this.mensajeLogout = "";
    this.mensajeLogout2 = "";
    this.modeloLogin.mailReg = "";
    this.modeloLogin.pwdReg1 = "";
    this.modeloLogin.pwdReg2 = "";
    console.log("login");
    if (this.userService.userYaCargado) {
      //no viene por aquí porque si ya ingresó ya no muestro el login
      console.log("login, user ya cargado: "); //+ this.modeloLogin.mail
    } else {
      console.log("login, primera carga: "); //+ this.modeloLogin.mail
      this.authService
      .afLogin(this.modeloLogin.mail, this.modeloLogin.pwd)
        .then(value => {
          /*
          console.log(
            "login, uid: " +
              value.user.uid +
              ", email verificado: " +
              value.user.emailVerified
          );
          */
          this.authService
            .getFireUserAut$(value.user.uid)
            .pipe(take(1))
            .subscribe(usrAut => {
              //console.log(userAut);
              usrAut.uid = value.user.uid;
              this.userService.userAutUpdate(usrAut);
              //console.log(userAut);
              /*
              console.log(
                "userAut: " +
                  userAut.uid +
                  " " +
                  userAut.email +
                  " " +
                  userAut.autorizado
              );
              */
              //para q pueda ver la info debe estar autorizado en usuarios/ + this.rutaEmp   y debe haber verificado el mail.
              if (usrAut.autorizado === "S" && value.user.emailVerified) {
                //Antes buscaba por: this.modeloLogin.mail, pero es más seguro por el uid
                this.authService
                  .getFireUser$(value.user.uid)
                  .pipe(take(1))
                  .subscribe(usr => {
                    if (typeof usr.email === "undefined") {
                      /*
                      console.log(
                        "login, uid: " +
                          value.user.uid +
                          " email no verificado "
                      );
                      */
                      this.mensajeLogin =
                        "Usuario no creado en esta empresa: " +
                        this.modeloLogin.mail;
                      this.mensajeLogin2 =
                        "Solicite a " +
                        this.modeloLogin.empresa +
                        " que se cree el usuario";
                    } else {
                      usr.uid = value.user.uid;
                      this.userService.userUpdate(usr);
                      this.userService.userYaCargadoUpdate(true);
                      /*
                      console.log(
                        "user: " +
                          user.uid +
                          " " +
                          user.email +
                          ", userYaCargado: " +
                          (userYaCargado ? "S" : "N")
                      );
                      */
                      this.modeloLogin.userYaCargado = true;
                      this.modeloLogin.mail = "";
                      this.modeloLogin.pwd = "";
                      //Apenas se ingresa se muestra el form de logout, por eso preparo los mensajes de logout:
                      this.mensajeLogout = "Usuario actual: " + this.userService.user.email;
                      this.mensajeLogout2 =
                        "Para cerrar la sesión o cambiar de usuario, presione Salir";
                      this.GetFireImgNoDisponible();
                      this.getFireLineas();
                      this.fireshopproductsService.getFireConfig();
                      this.getFireMaestros();
                      this.getFireProducts();
                      this.getFireProductsCompleto();
                    }
                  });
              } else {
                //por aquí viene solo si: a) no ha verificado el mail, o b) no está autorizado en usuarios/+ this.rutaEmp
                if (!value.user.emailVerified) {
                  console.log("login, uid: " + value.user.uid + " email no verificado ");
                  this.mensajeLogin =
                    "e-mail no verificado: " + this.modeloLogin.mail;
                  this.mensajeLogin2 =
                    "Cuando se registró le enviamos un e-mail con un enlace. Abralo y dé clic en el enlace para verificar que Usted es el propietario de esa dirección";
                } else {
                  /*
                  console.log(
                    "login, uid: " + value.user.uid + " no autorizado "
                  );
                  */
                  this.mensajeLogin =
                    "Usuario no autorizado: " + this.modeloLogin.mail;
                  this.mensajeLogin2 =
                    "Consulte con " +
                    this.modeloLogin.empresa +
                    " para solicitar autorización";
                }
              }
            });
        })
        .catch(err => {
          this.mensajeLogin = "Problema en el ingreso";
          console.log('Error login: ' + err.message.slice(0, 600));
          switch (err.code) {
            case "auth/invalid-email":
              this.mensajeLogin2 =
                "El e-mail no es válido, por favor revíselo.";
              break;
            case "auth/user-disabled":
              this.mensajeLogin2 =
                "El usuario está inhabilitado, por favor comuníquese con " +
                this.modeloLogin.empresa;
              break;
            case "auth/user-not-found":
              this.mensajeLogin2 =
                "Este usuario no existe, quizás está mal digitado o aún no se ha registrado.";
              break;
            case "auth/wrong-password":
              this.mensajeLogin2 =
                "La contraseña es incorrecta, por favor digítela nuevamente.";
              break;
            default:
              this.mensajeLogin2 = err.message;
              break;
          }
        });
    }
  }

  slideIndex:number;
  esteIntervalo;

  plusSlides(n):void {
    
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n):void {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;
    //console.log(slides[0]);
    if(slides[0]){
      var dots = document.getElementsByClassName("dot");
      if (n > slides.length) {this.slideIndex = 1}    
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex-1].style.display = "block";  
      dots[this.slideIndex-1].className += " active";
    } else {
      clearInterval(this.esteIntervalo);
    }
  }

  irALineaOIrASubnivel(lin:string, linId:number){
    this.prodService.estaLinea = lin;
    let tieneSubniveles = this.prodService.buscaLinSubNiveles(this.prodService.lineasNivel,this.prodService.estaLinea);
    if(tieneSubniveles){
      if(this.prodService.lineasNivel=="0"){
        this.prodService.lineasNivel = "1";
      }
      this.lineas1 = this.prodService.filtraLinNivel(this.prodService.lineasNivel,this.prodService.estaLinea);
    } else {
      clearInterval(this.esteIntervalo);
      this.router.navigate(["lineas/" + linId]);
    }

  }

  ngOnInit() {
    this.slideIndex = 1;
    this.showSlides(this.slideIndex);

    //************************************************************************ */
    //Suscripción a config$. Corre cada vez que se modifica la config
    this.config = [];
    this.slide1 = "";
    this.txtSl1 = "";
    this.slide2 = "";
    this.txtSl2 = "";
    this.slide3 = "";
    this.txtSl3 = "";
    this.empresa = "";
    this.okTimerSlides = false;
    this.valTimerSlides = 5000;

    this.configSub = this.prodService.config$.subscribe(p => {
      this.config = p;
      this.slide1 = p.slide1;
      this.txtSl1 = p.txtSl1;
      this.slide2 = p.slide2;
      this.txtSl2 = p.txtSl2;
      this.slide3 = p.slide3;
      this.txtSl3 = p.txtSl3;
      this.empresa = p.empresa;
      this.okTimerSlides = p.okTimerSlides;
      this.valTimerSlides = p.valTimerSlides * 1000;
      console.log("configSub recibido: " + JSON.stringify(this.config));
      /*
      Nota: la nueva "arrow notation": 
      //setInterval(()=>{this.plusSlides(1);},5000) 
      //funciona mejor que la forma //tradicional:
      //setInterval(function(){this.plusSlides(1);},5000)
      //pues el arrow pasa el scope "this" a la función interior y con ello puede
      //encontrar la función this.plusSlides
      //mientras que function() no encuentra la función.
      */

      if(this.esteIntervalo){
        clearInterval(this.esteIntervalo);
      }

      if(this.okTimerSlides){
        this.esteIntervalo = setInterval(()=>{ 
            this.plusSlides(1); 
          }, 
        this.valTimerSlides);
      } else {
        clearInterval(this.esteIntervalo);
      }
    });

    //************************************************************************ */
    this.modeloLogin.mail = 'ortegalopez111@gmail.com';
    this.modeloLogin.pwd = 'efisys111';
    //if(this.authService.isLoggedIn){
    if(this.prodService.productosInicioYaCargado){
      console.log("Logged in. Productos ya cargados")
      
      this.GetFireImgNoDisponible();
      this.getFireLineas();
      this.fireshopproductsService.getFireConfig();
      this.getFireMaestros();
      this.getFireProducts();
      this.getFireProductsCompleto();
      /*
      this.loginSubmit();
      */
    } else {
      console.log("Not logged in. Productos no cargados")
      this.loginSubmit();
    }

    this.lineasSub = this.prodService.lineas$.subscribe(li => {
      console.log("lineasSub");
      this.lineas=li;
      this.lineas1 = this.prodService.filtraLinNivel(this.prodService.lineasNivel,this.prodService.estaLinea);
/*
      this.soloLineas=[];
      this.soloSubLineas=[];
      for (const li1:Linea[] in this.lineas ) {
        if(li1.lin.length == 3) {
          this.soloLineas.push(li1);
        } 
        if (li1.lin.length == 3) {
          this.soloSubLineas.push(li1);
        }
      }
      this.lineas1 = this.soloLineas;
*/
      console.log("líneas  recibido: " + this.lineas.length);
    });

    this.productos = this.prodService.productos;
  }

  ngOnDestroy(){
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.lineasSub) {
      this.lineasSub.unsubscribe();
    }
  }
}
